<template>
  <div class='flex flex-col items-stretch lg:flex-row h-screen'>
    <div
      class='w-full lg:w-96 px-4 lg:px-16 py-4 lg:py-8 text-gray-50 flex flex-col items-start justify-start'
      :style='backgroundStyle'>
      <h1
        class='text-normal font-light border border-solid border-transparent py-1 pl-1 pr-4 rounded-md hover:border-gray-50 cursor-pointer'
        @click='goBack'>
        <arrow-narrow-left-icon class='h-4 inline-block' />
        Back
      </h1>
      <h1 class='text-xl lg:text-3xl font-light mt-4 lg:mt-8'>사전등록</h1>
    </div>
    <div class='p-8 lg:p-16 overflow-y-auto h-screen flex-grow'>
      <div class='w-full max-w-2xl'>
        <div class='mb-12'>
          <img 
            src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/koa-logo.jpeg'
            class='' />
        </div>
        <div class='mb-6'>
          <label class='uppercase text-sm text-gray-500 mb-2'>대한정형외과학회 회원 구분</label>
          <select
            v-model='membershipData.isKoaMember'
            @change='resetOver65'
            class='border py-2 px-2 rounded block w-full'>
            <option value='isMember'>
              회원
            </option>
            <option value='notMember'>
              비회원
            </option>
          </select>
          <label v-if='membershipData.isKoaMember == "isMember"'
            class='py-2 flex flex-row justify-start items-center gap-x-0.5 text-sm font-normal'>
            <input type='checkbox' v-model='membershipData.isKoaMemberOver65'>
            <span class='px-1'>만65세 이상이십니까?</span>
          </label>
        </div>
        <div class='mb-6'>
          <label class='uppercase text-sm text-gray-500 mb-2'>이름</label>
          <input
            v-model='profile.name'
            class='border py-2 px-2 rounded block w-full'
            type='text'
            placeholder='이름'>
        </div>
        <div class='mb-6'>
          <div class='flex flex-col lg:flex-row items-start lg:items-center l justify-between mb-2'>
            <label class='uppercase text-sm text-gray-500'>의사면허번호</label>
             <span class='text-xs text-gray-500'><span class='text-red-500'>*</span>의사면허번호가 없는 경우 생년월일 '6자리'를 입력해 주시기 바랍니다</span>
           </div>
          <input
            v-model='profile.licenseNumber'
            class='border py-2 px-2 rounded block w-full'
            type='text'
            placeholder='의사면허번호'>
        </div>
        <div class='mb-6'>
          <label class='uppercase text-sm text-gray-500 mb-2'>핸드폰번호</label>
          <input
            v-model='profile.phoneNumber'
            class='border py-2 px-2 rounded block w-full'
            type='text'
            placeholder='핸드폰번호'>
        </div>
        <div class='mb-6'>
          <label class='uppercase text-sm text-gray-500 mb-2'>근무처명</label>
          <input
            v-model='profile.organization'
            class='border py-2 px-2 rounded block w-full'
            type='text'
            placeholder='근무처명'>
        </div>
        <div class='mb-6'>
          <label class='uppercase text-sm text-gray-500 mb-2'>E-mail 주소</label>
          <input
            v-model='signupParams.email'
            class='border py-2 px-2 rounded block w-full'
            type='text'
            placeholder='E-mail 주소'>
        </div>
        <div class='mb-6'>
          <label class='uppercase text-sm text-gray-500 mb-2'>의료인 구분</label>
          <select
            v-model='membershipData.isMedicalProfessional'
            class='border py-2 px-2 rounded block w-full'>
            <option value='전문의'>
              전문의
            </option>
            <option value='전공의'>
              전공의
            </option>
            <option value='기타'>
              기타
            </option>
          </select>
        </div>
        <div v-if='!membershipData.isKoaMemberOver65'
          class='mb-6'>
          <label class='uppercase text-sm text-gray-500 mb-2'>입금자명</label>
          <input
            v-model='membershipData.depositor'
            class='border py-2 px-2 rounded block w-full'
            type='text'
            placeholder='입금자명'>
        </div>

        <div class='text-sm border rounded-md mb-8'>
          <div class='bg-gray-100 p-4'>
           
            <ol class='list-decimal pl-4'>
              <li class='py-1'>
                  <div class='text-gray-900 font-semibold'>개인정보를 제공받는 자</div>
                  <span>대한정형외과학회</span>
              </li>
              <li class='py-1'>
                  <div class='text-gray-900 font-semibold'>개인정보 이용 목적</div>
                  <span>학회 사전등록 신청 사실 확인 및 운영, 관리</span>
              </li>
              <li class='py-1'>
                  <div class='text-gray-900 font-semibold'>제공하는 개인정보 항목</div>
                  <span>회원구분, 이름, 면허번호, 생년월일, 핸드폰번호, E-mail 주소, 근무처명, 주소, 등록비, 입금자명</span>
              </li>
              <li class='py-1'>
                  <div class='text-gray-900 font-semibold'>개인정보 보유 및 이용기간</div>
                  <span>온라인 학술대회 종료일로부터 4개월까지</span>
                  <span>개인정보의 수집 및 이용을 거부할 수 있으며, 동의한 이후에도 언제든지 이를 철회할 수 있습니다. 다만, 개인정보의 수집 및 이용을 거부하는 경우 사전등록을 진행하실 수 없습니다.</span>
              </li>
            </ol>
          </div>
          <label class='border-t p-4 flex flex-row justify-start items-center gap-x-0.5 text-normal font-normal'>
            <input type='checkbox' v-model='extraInfo.disclaimer'>
            <span class='px-1'> 필수) 개인정보 수집 및 제3자 제공에 동의하고 신청하시겠습니까?</span>
          </label>
        </div>
        <ul class='mt-8 mb-6 list-disc list-inside text-red-600 text-xs text-left'>
          <li v-if='emptyName'>이름을 입력하세요</li>
          <li v-if='!validEmail'>E-mail 주소 형식으로 입력하세요</li>
          <li v-if='emptyMobile'>핸드폰번호를 입력하세요</li>
          <li v-if='emptyAffiliation'>근무처명을 입력하세요</li>
          <li v-if='emptyDepositor'>입금자명 입력하세요</li>
          <li v-if='!extraInfo.disclaimer'>개인정보 수집 및 제3자 제공에 동의해주세요</li>
        </ul>
        <button
          :disabled='disabledSignupButton'
          @click='submitSignup'
          class='border py-3 px-8 text-center text-white text-sm uppercase w-full bg-gray-900 block rounded-md'
          :class='disabledSignupButtonClass'>
          사전등록
        </button>
      </div>
    </div>

  </div>

</template>

<script>
import { mapFields }              from 'vuex-map-fields'
import { mapActions, mapState, mapGetters } from 'vuex'
import { ArrowNarrowLeftIcon } from 'vue-tabler-icons'

export default {
  name: 'Signup',
  components: {
    ArrowNarrowLeftIcon,
  },
  computed: {
    ...mapFields('users', [
      'signupParams',
      'profile.extraInfo',
      'membershipData',
      'profile',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    backgroundStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    emptyDepositor () {
      return !this.membershipData.depositor && !this.membershipData.isKoaMemberOver65
    },
    emptyEmailField () {
      return !this.signupParams.email
    },
    emptyPasswordField () {
      return !this.signupParams.password || 
             !this.signupParams.passwordConfirmation
    },
    emptyName () {
      return !this.profile.name
    },
    emptyAffiliation () {
      return !this.profile.organization
    },
    emptyMobile () {
      return !this.profile.phoneNumber || this.profile.phoneNumber.length < 6
    },
    validEmail () {
      let mailformat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
      return mailformat.test(this.signupParams.email)
    },
    disabledSignupButton () {
      return this.emptyEmailField || !this.validEmail ||
             this.emptyName || 
             this.emptyAffiliation ||
             this.emptyMobile ||
             this.emptyDepositor ||
             !this.extraInfo.disclaimer
    },
    disabledSignupButtonClass () {
      return this.disabledSignupButton ? 'opacity-50' : ''
    }
  },
  watch: {
    'profile.name': {
      handler: function (newVal) {
        this.signupParams.password = newVal
        this.signupParams.confirmPassword = newVal
      }
    }
  },
  methods: {
    ...mapActions('users', [
      'signup',
      'createProfile',
      'checkTokenStatus',
      'updateMembershipData',
    ]),
    goBack () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
    resetOver65 () {
      if (this.membershipData.isKoaMember == 'notMember') {
        this.membershipData.isKoaMemberOver65 = false
      }
    },
    redirectAfterAction () {
      let redirectRoute = this.$route.query.redirect_route ? this.$route.query.redirect_route : 'Home'
      this.$router.push({name: redirectRoute})
    },
    submitSignup () {
      if (this.hasValidToken) {
        this.createProfile() // will update if exists
      } else {
        this.signup().then(() => {
          this.createProfile().then(() => {
            this.updateMembershipData().then(() => {
              this.$router.push({ name: 'SignupConfirmation' })
            })
          })
        }).catch((error) => {
          if (error.response.status === 409) {
            this.$alert('이미 등록된 이메일입니다. 이메일을 확인 후 다시 등록해주세요.', {
              confirmButtonText: '확인',
              type: 'warning'
            })   
          } else {
            console.error(error)
          }
        })
      }
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (this.hasValidToken) {
        this.redirectAfterAction()
      }
    })
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>
